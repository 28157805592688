/*
 * @Author: your name
 * @Date: 2021-03-04 10:04:16
 * @LastEditTime: 2025-04-01 13:25:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\main.js
 */
import Vue from 'vue'
import VConsole from 'vconsole'
import App from './App.vue'
import router from './router'
import store from './store'
import './router/permission'
import './vant'
import './blueCross-ui'
import 'lib-flexible'
import './assets/styles/index.less'

// if (process.env.VUE_APP_ENV === 'dev') {
  new VConsole({ theme: 'light' })
// }
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
